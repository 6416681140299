import React, { useMemo, useReducer, useCallback, useState, useEffect } from 'react';
// import MonthView from './MonthView';
import './booking.scss';
import { Button, Container } from 'reactstrap';
import BookingProgress from './progress';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleLeft, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import MassageView from './MassageView';
import EmployeeView from './EmployeeView';
//const massages = require('./data/massages.json');
import { default as MassageList } from '../api/massages';
import { Massage } from '../api/massages';
import { Employee } from '../api/employees';
import { default as EmployeeList } from '../api/employees';
// import {
//     default as BookingReducer, initialState as DefaultBookingState
// } from './reducer';
import MonthView from './MonthView';
import { useStore } from '../../hooks/store';
import GuestDetails from './GuestDetails';
import PaymentDetails from './PaymentDetails';
import Confirmation from './Confirmation';
import ReviewBooking from './Review';

import './payment.scss';
import BookingInProgressVerification from './BookingInProgressVerification';
import './bookingmassages.scss';

const Booking: React.FC = () => {

    //let errors = [] as string[];
    //const [errors, setError] = useState([] as JSX.Element[]);
    const { state, dispatch } = useStore(true, ['BOOKING']);//todo: try to pass action type so that dispatch have explicity type check
    //const [state, dispatch] = useReducer(BookingReducer, DefaultBookingState);
    const bookingState = state['BOOKING'];
    //console.log(bookingState);
    // console.log(bookingState, MassageList, bookingState.step === 1);
    const [inprogressbooking, setinprogressbooking] = useState(false);

    const [inprogressbookingStatus, setInprogressbookingStatus] = useState(false);


    const handleMassageClickEvent = useCallback((massage: Massage) => {
        //dispatch({ type: "SELECT_MASSAGE", massage: massage });                
        dispatch("SELECT_MASSAGE", massage);
        dispatch("SET_ERROR", []);
    }, [dispatch]);
    const handleEmployeeClickEvent = useCallback((employee: Employee) => {
        // dispatch({ type: "SELECT_EMPLOYEE", employee: employee });
        dispatch("SELECT_EMPLOYEE", employee);
        dispatch("SET_ERROR", []);
    }, [dispatch]);

    const handlePreviousStepClickEvent = useCallback(() => {
        // dispatch({ type: "GOTO_STEP", step: newStep });
        if (bookingState.step === 1) {
            return;
        }
        dispatch("GOTO_STEP", bookingState.step - 1);
        dispatch("SET_ERROR", []);
    }, [dispatch, bookingState]);

    useEffect(() => {
        const currentbookingid = window.localStorage.getItem("currentbookingid");
        if (currentbookingid !== null) {
            setinprogressbooking(true);
        }
    }, []);



    const handleNextStepClickEvent = useCallback(() => {
        // dispatch({ type: "GOTO_STEP", step: newStep });
        //todo: move state to reducer, move error to error component
        if (bookingState.step === 1) {
            if (bookingState.massage === null) {
                // setError([<div key="step1">Please select massage</div>]);
                dispatch("SET_ERROR", [<div key="step1">Please select massage</div>]);
                return;
            }
        }
        else if (bookingState.step === 2) {
            if (bookingState.date === null || bookingState.slot === null) {
                // setError([<div>Please select slot.</div>]);
                dispatch("SET_ERROR", [<div key="step2">Please select slot.</div>]);
                return;
            }
        }
        else if (bookingState.step === 3) {
            if (bookingState.employee_key.length === 0) {
                // setError([<div>Please select masseur.</div>]);
                dispatch("SET_ERROR", [<div key="step3">Please select masseur.</div>]);
                return;
            }
        }
        dispatch("GOTO_STEP", bookingState.step + 1);
    }, [dispatch, bookingState]);

    // const errorHtml = useMemo(() => {

    // }, []);

    const massages = useMemo(() => {
        return MassageList.map((massage: Massage) => {
            // console.log(massage);
            return (
                <div key={massage.key} className="booking-massages__massage" onClick={() => handleMassageClickEvent(massage)}>
                    <MassageView massage={massage}
                        selected={bookingState.massage !== null ? bookingState.massage!.key === massage.key : false}>
                    </MassageView>
                </div>
            );
        });
    }, [handleMassageClickEvent, bookingState]);

    const employees = useMemo(() => {
        return EmployeeList.map((employee: Employee) => {
            return (
                <div key={employee.key} className="booking__employee" onClick={() => handleEmployeeClickEvent(employee)}>
                    <EmployeeView employee={employee}
                        selected={bookingState.employee_key !== null ? bookingState.employee_key! === employee.key : false}>
                    </EmployeeView>
                </div>
            );
        });
    }, [bookingState, handleEmployeeClickEvent]);

    const handleStartNewBookingClickEvent = useCallback(() => {
        window.localStorage.removeItem("currentbookingid");
        dispatch("GOTO_STEP", 1);
        dispatch("CLEAR");
        setinprogressbooking(false);
    }, [dispatch]);

    const handleContinueInProgressBookingClickEvent = useCallback(() => {
        //setinprogressbooking(false);
        fetch('/api/v1/booking/get?bookingid=' + window.localStorage.getItem("currentbookingid"), {
            //fetch('/api/v1/booking/get?bookingid=11b0b6c9-eb04-4444-be78-d42b0b6dd303', {
            method: 'GET'
        }).then(function (response: Response) {
            if (response.ok) {
                return response.json();
            }
            throw new Error("invalid booking");

        }).then((booking) => {
            let loadExistingBookingStatus = false;
            let appointmentDate = new Date(booking.massage.appointment);
            const todayDate = new Date();
            if (appointmentDate.getMonth() < todayDate.getMonth() &&
                appointmentDate.getFullYear() < todayDate.getFullYear() &&
                appointmentDate.getDate() < todayDate.getDate()) {
                window.localStorage.removeItem("currentbookingid");
                setinprogressbooking(false);
                return;
            }
            else {
                MassageList.forEach((massage) => {
                    if (massage.key === booking.massage.massage) {
                        loadExistingBookingStatus = true;
                        dispatch("SELECT_MASSAGE", massage);
                        return;
                    }
                });                
                if (loadExistingBookingStatus) {
                    loadExistingBookingStatus = false;
                    EmployeeList.forEach((employee) => {
                        if (employee.key === booking.massage.preferedMasseur) {
                            loadExistingBookingStatus = true;
                            dispatch("SELECT_EMPLOYEE", employee);
                            return;
                        }
                    });
                }
                if(loadExistingBookingStatus){
                //dispatch('SELECT_DATE', new Date(booking.massage.appointment), undefined);
                    dispatch('SELECT_SLOT', new Date(booking.massage.appointment), booking.massage.slot);
                    dispatch('UPDATE_CUSTOMER_DETAILS', booking.customer.firstName, booking.customer.lastName,
                        booking.customer.phoneNumber, booking.customer.email);
                }
                setinprogressbooking(false);
            }
            dispatch("GOTO_STEP", 1);
        }).catch((a) => {
            setInprogressbookingStatus(true);
        });
    }, [dispatch]);


    const xyz =
        <>
            {bookingState.step < 6 && (
                <>
                    <br></br>
                    <h5>Due to coronavirus (COVID-19) we have suspended our booking.</h5>
                    <h5>Please contact us if you have any question.</h5>
                    {/* <BookingProgress step={bookingState.step}></BookingProgress> */}
                </>
            )}
            <div className="booking__body">
                {/* <div className={"booking__step step" + bookingState.step}>
                    {bookingState.step === 1 && (
                        <>
                            <h5 className="booking__title">Select your massage</h5>

                            <div className="booking-massages">
                                {massages}
                            </div>
                        </>
                    )}

                    {bookingState.step === 2 && (
                        <>
                            <h5 className="booking__title">When would you like to visit us?</h5>
                            <div>
                                {bookingState.slot && bookingState.date &&
                                    <>You have selected {bookingState.slot} on  {bookingState.date.getDate() + "-" + (bookingState.date.getMonth() + 1) +
                                        "-" + bookingState.date.getFullYear()}
                                    </>
                                }
                            </div>
                            <div className="booking__calendar">
                                <MonthView></MonthView>
                            </div>
                        </>
                    )}
                    {bookingState.step === 3 && (
                        <>
                            <h5 className="booking__title">Tell us your masseur preferance</h5>
                            <div className="booking__employees">
                                <div className="booking__employees-info">
                                    Please note that this selection is only preferance. We do not gurantee that you will get the masseur which you will select.
                                    But if you let us know your preferance than we will do our best to assign that masseur based on availability.
                            </div>
                                {employees}
                            </div>
                        </>
                    )}
                    {bookingState.step === 4 && (
                        <>
                            <h5 className="booking__title">Contact Details</h5>
                            <div className="booking__customer">
                                <GuestDetails onPrevClick={handlePreviousStepClickEvent}
                                    onNextClick={handleNextStepClickEvent}></GuestDetails>
                            </div>
                        </>
                    )}
                    {bookingState.step === 5 && (
                        <>
                            <h5 className="booking__title">Review & Pay</h5>
                            <div className="booking__review-payment">
                                <ReviewBooking></ReviewBooking>
                                <PaymentDetails>
                                    <Button disabled={bookingState.step === 1 ? true : false} className="booking__button"
                                        onClick={handlePreviousStepClickEvent}>Back</Button>
                                </PaymentDetails>
                            </div>
                        </>
                    )}
                    {bookingState.step === 6 && (
                        <>
                            <div className="booking__confirmation">
                                <Confirmation></Confirmation>
                            </div>
                        </>
                    )}
                    {
                        (bookingState.step <= 3) &&
                        (
                            <>
                                {bookingState.errors}
                                <div className="booking__navigation">
                                    <Button disabled={bookingState.step === 1 ? true : false} className="booking__button"
                                        onClick={handlePreviousStepClickEvent}>Back</Button>
                                    <Button disabled={bookingState.nextProgress}
                                        className={"booking__button" + (bookingState.nextProgress ? ' --in-progress' : '')}
                                        onClick={handleNextStepClickEvent}>
                                        Next{bookingState.nextProgress && <div className="loader"></div>}
                                    </Button>
                                </div>
                            </>
                        )
                    }
                </div> */}
            </div>
        </>;

    return (
        <>

            <Container className="page-container booking">

                {inprogressbooking &&
                    <>
                        <h1>Booking</h1>
                        <BookingInProgressVerification
                            showWarning={inprogressbookingStatus}
                            onContinueBookingClick={handleContinueInProgressBookingClickEvent}
                            onNewBookingClick={handleStartNewBookingClickEvent}></BookingInProgressVerification>
                    </>
                }
                {!(inprogressbooking) &&
                    xyz
                }

            </Container>
        </>
    );
};

export default Booking;
