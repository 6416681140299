import React, { } from 'react';
import {  Container, NavLink, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '../../hooks/store';

const HeaderMenu2: React.FC = () => {
    const { state: sessionState, dispatch } = useStore(true, ['SESSION']);
    return (
        <div className="header-menu">
            <Row>
                <NavLink tag={Link} className="text-dark site-link" to="/about-us">About Us</NavLink>
                <NavLink tag={Link} className="text-dark site-link" to="/services">Services</NavLink>
                {/* <NavLink tag={Link} className="text-dark site-link" to="/booking">Book Now</NavLink> */}
                {/* <NavLink tag={Link} className="text-dark site-link" to="/team">Team</NavLink> */}
                <NavLink tag={Link} className="text-dark site-link" to="/reviews">Reviews</NavLink>
                <NavLink tag={Link} className="text-dark site-link" to="/contact-us">Contact Us</NavLink>
            </Row>
        </div>
    );
};

export default HeaderMenu2;