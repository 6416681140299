import React, { useCallback, useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Container, Alert } from 'reactstrap';
import './contactus.scss';


const ContactUs: React.FC = () => {
    const [submitingForm, setSubmitingForm] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const handlSubmitFormClickEvent = useCallback((event: any) => {
        event?.preventDefault();
        setSubmitingForm(false);
        setShowSuccess(false);
        setShowError(false);
        let validationStatus = true;
        if (validationStatus) {
            setSubmitingForm(true);
            const data = new FormData(event.target);

            fetch('/api/contactus/send', {
                method: 'POST',
                body: data
            }).then(function (response) {
                return response.json();
            }).then((result) => {
                if(result.status){
                    const form : any | null = window.document.getElementById("contactus");
                    if( form !== null){
                        form.reset();
                    }
                    setShowSuccess(true);
                }
                else{
                    setShowError(true);
                }
                setSubmitingForm(false);
            });



        }

        return false;
    }, []);

    return (
        <Container className="page-container contact-us">
            <h1>How Can We Help?</h1>
            <p className="text-line1">We love hearing from you and would be happy to answer any questions you may have.</p>
            <p className="text-line1">
                To send us an email, simply use the form below or use <b>phatraboonmee380@gmail.com</b>.
          Alternatively, you can find us on google map or give us a good old fashioned phone call.</p>
            <div className="contact-us__section1">

                <Form onSubmit={handlSubmitFormClickEvent} noValidate={true}
                    autoComplete="off" className="contact-us__form" name="contactus" id="contactus">
                    <FormGroup>
                        <Label for="username">Name*</Label>
                        <Input type="text" name="username" id="username" placeholder="" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Email address*</Label>
                        <Input type="email" name="email" id="email" placeholder="" />
                    </FormGroup>

                    <FormGroup>
                        <Label for="Message">Message*</Label>
                        <Input type="textarea" name="Message" id="message" />
                    </FormGroup>
                    {showError &&
                        <Alert color="danger" className="contact-us__error">
                            <p className="">Something went wrong. We could not submit your form. Please try again or use alternative method to contact us.</p>
                        </Alert>
                    }
                    {showSuccess &&
                        <Alert color="success" className="contact-us__error">
                            <p className="">Your details have been submitted. We will get back to you as soon as possible.</p>
                        </Alert>
                    }
                    <Button disabled={submitingForm} color="primary" block
                        className={"contact-us__action" + (submitingForm ? ' --in-progress' : '')}>
                        Submit{submitingForm && <div className="loader"></div>}
                    </Button>
                </Form>
                <iframe title="Sabai Sabai Thai Therapist Location" className="contact-us__map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2486.45847627223!2d-1.0192824839279322!3d51.44973822273813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48769b52182dde15%3A0x4a8350d2af09f819!2sSabai%20Sabai%20Thai%20Therapist!5e0!3m2!1sen!2suk!4v1605405950147!5m2!1sen!2suk"
                    width="100%" height="100%" frameBorder="0" allowFullScreen={true} aria-hidden="false" tabIndex={0}></iframe>
                <div className="contact-us__info">
                    <div className="contact-us__info-section">
                        <div className="contact-us__address">
                            <h5>Address</h5>
                            <p>45 Meadway Precinct</p>
                            <p>Reading</p>
                            <p>RG30 4AA</p>
                            <p>United Kingdom</p>
                        </div>
                        <div className="contact-us__phone">
                            <h5>Telephone</h5>
                            <p>07990 650 993</p>
                        </div>
                        <div className="contact-us__email">
                            <h5>Email</h5>
                            <p> phatraboonmee380@gmail.com</p>
                        </div>
                    </div>
                    <div className="contact-us__openingtime">
                        <h5>Opening Times</h5>
                        <p className="--day">Monday</p>
                        <p className="--time">10:00 am - 8:00 pm</p>
                        <p className="--day">Tuesday</p>
                        <p className="--time">10:00 am - 8:00 pm</p>
                        <p className="--day">Wednesday</p>
                        <p className="--time">10:00 am - 8:00 pm</p>
                        <p className="--day">Thursday</p>
                        <p className="--time">10:00 am - 8:00 pm</p>
                        <p className="--day">Friday</p>
                        <p className="--time">10:00 am - 8:00 pm</p>
                        <p className="--day">Saturday</p>
                        <p className="--time">10:00 am - 8:00 pm</p>
                        <p className="--day">Sunday</p>
                        <p className="--time">10:00 am - 6:00 pm</p>
                    </div>
                </div>

            </div>
            {/* <h1>Call Us</h1>
            <h1>Find Us</h1> */}


        </Container>
    );
};

export default ContactUs;