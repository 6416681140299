import React, { useState, useCallback, useEffect } from 'react';
import { Container, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faUser, faTimes } from '@fortawesome/free-solid-svg-icons';
import BreakPoint from '../mediaquery/breakpoint';
import { Link } from 'react-router-dom';
import { useStore } from '../../hooks/store';
import HeaderMenu2 from './HeaderMenu2';
import './offer.scss';

const TopNavigation: React.FC = () => {

    const { state: sessionState, dispatch } = useStore(true, ['SESSION']);
    const [isCollapsed, setCollapsedStatus] = useState(false);

    const handleToggleClickEvent = useCallback(() => {
        setCollapsedStatus(!isCollapsed);
        var scrollLock = document.createAttribute("data-scroll-lock");
        scrollLock.value = (!isCollapsed).toString();
        document.body.attributes.setNamedItem(scrollLock);
    }, [isCollapsed]);
    useEffect(() => {
        // fetch('/api/security/getuser', {
        //     method: 'GET'
        // }).then(function (response) {
        //     //console.log(response)
        //     return response.json();
        // }).then((result) => {
        //     if (result.loggedin) {
        //         dispatch("UPDATE_LOGIN", true, result.username, result.type, result.isAdmin);
        //     }
        //     else {

        //     }
        // });
    }, []);

    return (
        <>
        {/* <NavLink tag={Link} className="offer-container" to="/vouchers">Enjoy our Seasonal vouchers including up to £20.00 off</NavLink> */}
        <header className="">
            <link href="https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap" rel="stylesheet"></link>
           
            {/* <p className="home__title1"></p> */}
            <Container className="top-nav">
                <BreakPoint down="MD" >
                    <button type="button" className="top-nav__toggler" onClick={handleToggleClickEvent}>
                        <span className="top-nav__toggler-icon"></span>
                    </button>
                </BreakPoint>
                
                {/* <button type="button" className="mr-2 navbar-toggler"><FontAwesomeIcon icon={faBars} size="1x" /></button> */}
                <div className="top-nav__bar">
                    <span className="top-nav__title"><NavLink tag={Link} className="text-dark" to="/">Sabai Sabai Thai Therapist</NavLink></span>
                    {/* <div className="nav-item"> */}
                        {/* <BreakPoint up="SM" > */}
                        {/* <FontAwesomeIcon icon={faPhone} size="1x" className="nav-icon" /> */}
                        {/* </BreakPoint> */}
                        {/* <a className="nav-link" href="tel:234234324">a22 234 3434</a> */}
                    {/* </div> */}
                    <BreakPoint up="LG" ><HeaderMenu2></HeaderMenu2></BreakPoint>
                </div>
            </Container>
            <div className={"slide-menu" + (isCollapsed ? " --visible" : "")}>
                <Container>
                    <NavLink tag={Link} onClick={handleToggleClickEvent} className="text-dark" to="/about-us">About Us</NavLink>
                    <NavLink tag={Link} onClick={handleToggleClickEvent} className="text-dark" to="/services">Services</NavLink>
                    {/* <NavLink tag={Link} onClick={handleToggleClickEvent} className="text-dark" to="/booking">Book Now</NavLink> */}
                    {/* <NavLink tag={Link} onClick={handleToggleClickEvent} className="text-dark" to="/team">Team</NavLink> */}
                    {/* <NavLink tag={Link} onClick={handleToggleClickEvent} className="text-dark" to="/">News</NavLink> */}
                    <NavLink tag={Link} onClick={handleToggleClickEvent} className="text-dark" to="/reviews">Reviews</NavLink>
                    <NavLink tag={Link} onClick={handleToggleClickEvent} className="text-dark" to="/contact-us">Contact Us</NavLink>
                    {/* <NavLink tag={Link} onClick={handleToggleClickEvent} className="text-dark --christmas" to="/">Christmas Special</NavLink> */}                    
                </Container>
            </div>
            <button onClick={handleToggleClickEvent} className={"slide-menu__bg" + (isCollapsed ? " --visible" : "")}></button>
        </header>
        </>
    );
};


export default TopNavigation;