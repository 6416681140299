import React, { } from 'react';
import { Button, Col, Container, Row, Alert } from 'reactstrap';
import './booking-inprogress.scss';

type BookingInProgressVerificationType = {
    onNewBookingClick(event: React.MouseEvent<HTMLButtonElement>): void;
    onContinueBookingClick(event: React.MouseEvent<HTMLButtonElement>): void;
    showWarning: boolean;
};

const BookingInProgressVerification: React.FC<BookingInProgressVerificationType> = (props) => {

    return (
        <>
            {/* <Container className="booking-inprog__popup">

            </Container> */}
            <Container className="booking-inprog">
                <Row>
                    <Col className="booking-inprog__textline1">
                        It looks like you have one booking in progress. Would you like to continue with that booking or start a new one?
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button block color="primary" onClick={props.onContinueBookingClick}>Cotninue</Button>
                    </Col>
                    <Col>
                        <Button block color="primary" onClick={props.onNewBookingClick}>Start New</Button>
                    </Col>
                </Row>



            </Container>
            { props.showWarning &&
                <Alert color="warning">
                    We are very sorry but it seems that we could not find your in progress booking.
                    Please start new booking if issue persists or contact us if you have any question.
            </Alert>
            }
        </>
    );
};

export default BookingInProgressVerification;
