import { initStore } from "../hooks/store";
// import { mediaQueryContextType } from "../components/mediaquery/context";

export type mediaQueryStateType = {
    breakpoints: Map<string, boolean>;
    queries: Map<string, string>;
    current: string;
};

type mediaQueryActionType =
    | { type: 'UPDATE_BREAKPOINT_STATUS', media: string, match: boolean }
    | { type: 'SET_BREAKPOINT_QUERIES', query: string, breakpoint: string };

const initialState: mediaQueryStateType = {
    breakpoints: new Map<string, boolean>(),
    queries: new Map<string, string>(),
    current: ""
};
// type widthMediaQuery = Map<string, string>;


export const mediaQueryReducer = (previousState: mediaQueryStateType, action: mediaQueryActionType): mediaQueryStateType => {
    switch (action.type) {
        case 'SET_BREAKPOINT_QUERIES':
            let newQueries = new Map(previousState.queries);
            newQueries.set(action.query, action.breakpoint);
            return { ...previousState, queries: newQueries };
        case 'UPDATE_BREAKPOINT_STATUS':
            let newBreakPoints = new Map(previousState.breakpoints);
            newBreakPoints.set(action.media, action.match);

            let currentBreakpoint: string = "";
            newBreakPoints.forEach((value: boolean, key: string) => {
                if (value)
                    currentBreakpoint = key;
            });

            return { ...previousState, breakpoints: newBreakPoints, current: previousState.queries.get(currentBreakpoint) || '' };
        default:
            return previousState;
    }

};

// const findCurrentBreakPoint = (breakpoints: Map<string, boolean>, mqc: any) => {
//     let activeMedia: string = "";
//     breakpoints.forEach((value: string, key: string) => {
//         if (value)
//             activeMedia = key;
//     });
//     return mqc.widthMQ.get(activeMedia) || '';
//     // console.log(mqc.widthMQ.get(activeMedia) ||  mqc.defaultMode);
// };

export const actions = {
    UPDATE_BREAKPOINT_STATUS: (previousState: mediaQueryStateType, media: string, match: boolean) => {
        return mediaQueryReducer(previousState, { type: 'UPDATE_BREAKPOINT_STATUS', media, match });
    },
    SET_BREAKPOINT_QUERIES: (previousState: mediaQueryStateType, query: string, breakpoint: string) => {
        return mediaQueryReducer(previousState, { type: 'SET_BREAKPOINT_QUERIES', query, breakpoint });
    }
};

const configureStore = () => {
    initStore("MEDIA_QUERY", true, actions, initialState);
};

export default configureStore;