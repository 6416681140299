import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Col, Container, Row } from 'reactstrap';
import './reviews.scss';
import { default as EmployeeList, Employee } from '../api/employees';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { GoogleReview, GoogleReviewResult } from '../api/reviews';
import Review from './Review';

const Reviews: React.FC = () => {

    const [error, setError] = useState(false);
    const [reviewResponse, setReviewResponse] = useState<GoogleReviewResult>(null);

    useEffect(() => {
        fetch('/api/googlereview/reviews', {
            method: 'GET'
        }).then(function (response) {
            return response.json();
        }).then((result) => {
            setReviewResponse(result.result);
            setError(result.status !== "OK");
        }).catch((error) => {
            setError(true);
        });
    }, []);

    const myReviews = useMemo(() => {
        if (reviewResponse !== null) {
            return reviewResponse!.reviews.map((review: GoogleReview, index: number) =>
                <>
                    <Review key={index} review={review}></Review>
                </>
            );
        }
    }, [reviewResponse]);

    const handleWriteReviewClick = useCallback(() => {
        window.open('https://search.google.com/local/writereview?placeid=ChIJFd4tGFKbdkgRGfgJr9JQg0o');
    }, []);

    const handleReadMoreReviewClick = useCallback(() =>{
        window.open('https://search.google.com/local/reviews?placeid=ChIJFd4tGFKbdkgRGfgJr9JQg0o');
    }, []);

    return (
        <>
            <Container className="page-container reviews">
                <h1>Reviews</h1>
                <div className="reviews-container">
                    {error &&
                        <Alert color="info">
                            Failed to load reviews.
                        </Alert>
                    }
                    {myReviews}
                    <div className="reviews-actions">
                        <Button color="primary" onClick={handleReadMoreReviewClick}>Read More</Button>
                        <Button color="primary" onClick={handleWriteReviewClick}>Write a Review</Button>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Reviews;