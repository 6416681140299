import React, { useCallback, useMemo, useState } from 'react';
import { Container } from 'reactstrap';
import './reviews.scss';
import { default as EmployeeList, Employee } from '../api/employees';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { GoogleReview } from '../api/reviews';

export type ReviewPropType = {
    review: GoogleReview
};

const Review: React.FC<ReviewPropType> = (props) => {

    return (
        <div className="review-view">
            <a href={props.review.author_url} className="review-view__author-pic">
                <img src={props.review.profile_photo_url} alt={props.review.author_name}></img>
            </a>
            <div className="review-view__info">
                <p className="review-view__author">{props.review.author_name}</p>
                <div className="review-view__rating">
                    <FontAwesomeIcon icon={faStar}
                        size="1x" className="review-view__star" fontWeight="100" />
                    <FontAwesomeIcon icon={faStar}
                        size="1x" className="review-view__star" fontWeight="100" />
                    <FontAwesomeIcon icon={faStar}
                        size="1x" className="review-view__star" fontWeight="100" />
                    <FontAwesomeIcon icon={faStar}
                        size="1x" className="review-view__star" fontWeight="100" />
                    <FontAwesomeIcon icon={faStar}
                        size="1x" className="review-view__star" fontWeight="100" />
                    <p className="review-view__time">{props.review.relative_time_description}</p>
                </div>
                <p className="review-view__text">{props.review.text}</p>
            </div>
        </div>
    );
};

export default Review;