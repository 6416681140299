import React, { } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import { MediaQueryContextProvider } from './components/mediaquery/context';
import configureMediaQueryStore from './stores/mediaquery';
import configureBookingStore from './stores/booking';
import configureSessionStore from './stores/security';

import './custom.css'
import AboutUs from './components/AboutUs';
import Massages from './components/Massages';
import ContactUs from './components/ContactUs';
//import Login from './components/Login';
import Login from './components/security/Login';
import Booking from './components/booking/Booking';
import Register from './components/security/Register';
import MyBookings from './components/mybookings/MyBookings';
import Team from './components/team/Team';
import Reviews from './components/reviews/Reviews';
import Christmas from './components/offers/Christmas';
import Vouchers from './components/offers/Vouchers';

function App() {
  //static displayName = App.name;
  configureMediaQueryStore();
  configureBookingStore();
  configureSessionStore();
  
    return (
      <MediaQueryContextProvider>
        <Layout>
          <Route exact path='/' component={Home} />
          <Route path='/about-us' component={AboutUs} />
          <Route path='/services' component={Massages} />
          <Route path='/contact-us' component={ContactUs} />
          {/* <Route path='/sign-in' component={Login} /> */}
          <Route path='/booking' component={Booking} />
          {/* <Route path='/sign-up' component={Register} /> */}
          {/* <Route path='/mybookings' component={MyBookings} /> */}
          {/* <Route path='/vouchers' component={Vouchers} /> */}
          {/* <Route path='/team' component={Team} /> */}
          <Route path='/reviews' component={Reviews} />
        </Layout>
      </MediaQueryContextProvider>
    );
  
}

export default App;
