import { initStore } from "../hooks/store";
import { Massage } from "../components/api/massages";
import { Employee } from "../components/api/employees";

export type State = {
        isLoggedIn: false,
        userName: string,
        userType: string,
        isAdmin: boolean,
};

export const initialState: State = {
    isLoggedIn: false,
    userName: "",
    userType: "guest",
    isAdmin: false
};

export type Action =
    | { type: 'UPDATE_LOGIN', isLoggedIn: boolean, userName: string, userType: string, isAdmin: boolean };


const reducer = (previousstate: State, action: Action) => {

    switch (action.type) {
        case "UPDATE_LOGIN":
            return {
                ...previousstate, isLoggedIn : action.isLoggedIn, userName : action.userName,
                userType: action.userType, isAdmin : action.isAdmin
            };
        default:
            return previousstate;
    }
};


export const actions = {
    UPDATE_LOGIN: (previousState: State, isLoggedIn: boolean, userName: string, userType: string, isAdmin: boolean) => {
        return reducer(previousState, { type: 'UPDATE_LOGIN', isLoggedIn, userName, userType, isAdmin });
    }
};

const configureStore = () => {
    initStore("SESSION", true, actions, initialState);    
};

export default configureStore;