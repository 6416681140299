import { useState, useEffect, useMemo } from 'react';

// let globalState: any = {};
// let listeners: any = [];
// let states: any = [];
let actions: any = {};
let states = new Map<string, number>();
// let actions2 = new Map<string, any>();

//better to split each array into individual item. with same key, any prototype
type storeItem = {
    // key: string;
    listeners: any;
    state: any;
    //  actions: any;
    gloabl: boolean;
};

let masterState = new Map<string, storeItem>();

export const useStore = (shouldListen: boolean = true, subscribe: string[] = []):
    {
        state: any,
        dispatch: (actionIdentifier: any, ...payload: any) => void,
        get: <T>(key: string) => T,
        states: any
    } => {
    const [, setState] = useState(1);

    // console.log("i am in usestore render", state);

    const subscribedState = useMemo(() => {
        let s: any = {};
        if (subscribe.length > 0) {
            subscribe.forEach((item: string) => {
                let asdf = masterState.get(item);
                if (asdf) {
                    s[item] = asdf.state;
                }
            });
            return s;
        }
        return {};
    }, [subscribe]);

    // console.log("subscribedState state is ", subscribedState);

    const dispatch = ((actionIdentifier: any, ...payload: any) => {
        // console.log(actionIdentifier);

        const key2 = actions[actionIdentifier](...payload);
        //console.log(key2);
        let stateItem = masterState.get(key2);
        // console.log("updated state item", stateItem, key2, states.get(key2)!, states)
        // console.log("i am here");
        if (stateItem) {
            // console.log("i am here", stateItem.listeners);
            stateItem.listeners.forEach((listener: any) => {
                // console.log("i am here", states.get(key2));
                listener((states.get(key2)! + 1));
                states.set(key2, (states.get(key2)! + 1));
            });
        }

        //console.log(globalState);


        //globalState = { ...globalState, ...newState };
        //states = states.map((s: any) => !s);
        //let index = 0;
        // for (const listener of listeners) {
        //     //listener(states[index]);
        //     listener(!state);
        //     //  index++;
        // }

        //masterState.get()
    });

    // useEffect(() => {
    //     // if (shouldListen) {
    //     listeners.push(setState);
    //     //     states.push(state);
    //     // }

    //     return () => {
    //         //     if (shouldListen) {
    //         listeners = listeners.filter((li: any) => li !== setState);
    //         //     }
    //     };
    // }, [setState, shouldListen, state]);

    const get = (key: string): any => {
        let x = masterState.get(key);
        return x ? x.state : null;
    };

    useEffect(() => {
        // console.log(masterState);
        // console.log("store useeffect")
        if (shouldListen) {
            subscribe.forEach((key: string) => {
                let stateItem = masterState.get(key);
                if (stateItem) {
                    // debugger;
                    stateItem.listeners.push(setState);
                    masterState.set(key, stateItem);
                    return;
                }
            });
        }
        // console.log(masterState);
        return () => {
            // console.log(" removing listener");
            if (shouldListen) {
                subscribe.forEach((key: string) => {
                    let stateItem = masterState.get(key);
                    if (stateItem) {
                        stateItem.listeners = stateItem.listeners.filter((li: any) => li !== setState);
                        return;
                    }
                });
            }
        };
    }, [subscribe, setState, shouldListen]);

   
    // const get = function getme<T>(key: string): T {
    //     let x = masterState.get(key);
    //     return x ? masterState.get(key)?.state : null;
    // };
   // const get = () => {};

    return {state: subscribedState, dispatch, get, states};
};

// export const initStore = (userActions: any, initialState: any) => {
//     if (initialState) {
//         globalState = { ...globalState, ...initialState };
//     }
//     actions = { ...actions, ...userActions };
// };

export const initStore = (key: string, global: boolean, userActions: any, initialState: any) => {
    if (!masterState.has(key)) {
        //console.log(initialState);
        //globalState = { ...globalState, ...initialState };
        //console.log(Object.keys(userActions));

        // let act  = new Array(Object.keys(userActions).length);

        // Object.keys(userActions).forEach((key2: string) => {
        //     const fn = userActions[key2];
        //     act[act.length] = function(){
        //         console.log(key);
        //         return fn();
        //     }
        // });

        let act: any = {};
        states.set(key, 1);
        Object.keys(userActions).forEach((key2: string) => {
            const fn = userActions[key2];
            act[key2] = (...payload: any) => {
                let stateItem = masterState.get(key);
                // console.log("old state item", stateItem, key, states.get(key)!, states)
                if (stateItem) {
                    stateItem.state = fn(stateItem.state, ...payload);


                    // stateItem.listeners.forEach((listener: any) => {
                    //     console.log("i am here", states.get(key));
                    //     const newv = (states.get(key)! + 1);
                    //     console.log(newv);
                    //     listener(newv);
                    //     states.set(key, newv);
                    // });


                    return key;
                }
                throw Error("missing");
            }
        });


        // for(var key2 in userActions){
        //     console.log(userActions[key2]);

        // }
        // userActions.entries((element : any) => {
        //     console.log(element);
        // });

        const newStoreItem: storeItem = {
            // actions: userActions,
            state: initialState,
            listeners: [],
            gloabl: global
        };
        masterState.set(key, newStoreItem);

        actions = { ...actions, ...act };
    }
    //actions2.set(key, userActions);
    //actions = { ...actions, ...userActions };

};


