import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Button, Carousel, CarouselItem, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import './home/home.scss';
import { default as MassageList, Massage } from './api/massages';

const Home: React.FC = () => {
	const history = useHistory();
	const [headerImageLoaded, setHeaderImageLoaded] = useState(false);
	const headerImageTextBlock = useMemo(() => {
		return (
			<div className={"home__image" + (headerImageLoaded ? ' -background' : ' ')}>
				<div className="home__image-titlecontainer">
					<p className="home__image-title1">Welcome To</p>
					<p className="home__image-title2">Sabai Sabai Thai Therapist</p>
				</div>
			</div>
		);
	}, [headerImageLoaded]);

	const handleBookNowClick = useCallback(() => {
        window.scrollTo(0,0);
        history.push('/booking')
	}, [history]);
	
	const handleContactUsClick = useCallback(() => {
        window.scrollTo(0,0);
        history.push('/contact-us')
	}, [history]);
	
	const handleServiceClick = useCallback(() => {
        window.scrollTo(0,0);
        history.push('/services')
    }, [history]);

	const bookNowBlock = useMemo(() => {
		return (
			<div className="home-booknow">
				<div className="home-booknow__titlecontainer">
					<p className="home-booknow__title2">Best massage near you in Reading</p>
					<p className="home-booknow__title1">Do not miss the wonderful massage experience at the Sabai Sabai Thai Therapist</p>
					<p className="home-booknow__title1">45 Meadway Precinct, Reading, RG30 4AA, United Kingdom</p>
					{/* <Button color="primary" onClick={handleBookNowClick} className="home-booknow__action">Book Now</Button> */}
				</div>
			</div>
		);
	}, []);

	useEffect(() => {
		let image = new Image(1, 1);
		image.src = "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/assets/home/home1.jpg";
		if (image !== null && image !== undefined) {
			image.onload = function (event: any) {
				setHeaderImageLoaded(true);
			};
		}
	}, []);

	

	const massages = useMemo(() => {
		return MassageList.map((massage: Massage) => {

			const imageIndex = Math.floor(Math.random() * (massage.images.length - 1)) + 1;

			return (
				<div key={massage.key} className="home-massages__massage">
					<img src={massage.image + massage.images[imageIndex]} alt={massage.name}></img>
					{/* <div className="home-massages__massage-img"></div> */}
					<div className="home-massages__massage-overlay"></div>
					<div className="home-massages__massage-info">
						<p className="home-massages__massage-name">{massage.name}</p>
						<Button onClick={handleServiceClick} color="primary" className="home-massages__massage-readmore">Read More</Button>
					</div>
				</div>
			);
		});
	}, []);

	

	return (
		<>
			{/* <div className="home-image"></div> */}
			{/* <p className="home__title1">SPREADING POSITIVE ENERGY AND HEALING THE BODY TROUGH THE ART OF THAI MASSAGE</p> */}
			{/* <HomeCarousel></HomeCarousel> */}


			<Container className="page-container home">

				{headerImageTextBlock}
				{/* <div className="image1">
        <img src="https://sabaitherapist.co.uk/wp-content/uploads/2019/02/lomi-lomi-Massage-Fotolia.jpg" width="100%" height="100%" alt=""/>
        <span>Our mission is to help people to improve themselves and heal their body</span>
      </div> */}
				{/* <img src="/images/home/home1.jpg" alt="image1"></img> */}

				{/* <div className="image1"></div> */}
				{/* <p>Our mission is to help people to improve themselves and heal their body</p> */}
				<div className="home__section1">
					<div className="home__section1-container">
						<p className="home__title2">SPREADING POSITIVE ENERGY AND HEALING THE BODY TROUGH THE ART OF THAI MASSAGE</p>
					</div>
				</div>
				<div className="home__section2">
					<div className="home__section2-container">
						{/* <p className="home__title1">SPREADING POSITIVE ENERGY AND HEALING THE BODY TROUGH THE ART OF THAI MASSAGE</p> */}
						<p>We are Sabai Sabai Thai Massage Therapy and we’d like to welcome you to our beautiful world of massage and healing. We are hoping you’ll enjoy our services and that your body will benefit from them as well.</p>
						<p>
							The art of Thai Massage has been perfected and passed down from generation to generation until it reached the form that it is today – a perfect form of recreation and relaxation that takes healing your body as it’s main priority. Your body is your temple.</p>
						<p>
							Our massages range from relaxing & soothing to theraputic & healing so we encourage you to try all of them out. All of them have a range of healing properties for all kinds of problems you might face in your everyday life. For a full list visit the massages page.</p>
						<p>
							And remember to spread the positive energy with your loved ones and the special people in your life with the help of our massage gift vouchers that you can find on the gift voucher page.</p>
					</div>
				</div>
				<div className="home__section1">
					<div className="home__section1-container">
						<p className="home__title1">Our Services</p>
					</div>
				</div>
				<div className="home__section2 massage-section">
					<div className="home__section1-container home-massages">
						{massages}
					</div>
				</div>
				{bookNowBlock}
				
				<div className="home__section2">
					<div className="home__section2-container home-contactus">
						<p className="home-contactus__title">Contact Us</p>
						<p className="home-contactus__line1">We love hearing from you and would be happy to answer any questions you may have.</p>
						<p className="home-contactus__line1">To send us an email, simply use the button below or use phatraboonmee380@gmail.com.
							Alternatively, you can find us on google map or give us a good old fashioned phone call.</p>
						<p className="home-contactus__line1">For more details visit our Contact Us page</p>
						<Button color="primary" onClick={handleContactUsClick} className="home-contactus__action">Get in touch</Button>
					</div>
				</div>
				{/* <h5>Our mission is to help people to improve themselves and heal their body</h5> */}
				{/* <h5>Why not try out a relaxing thai foot massage?</h5> */}
				{/* <img src="/images/home/relax2.webp" width="100%" alt="" title=""
          className="" ></img> */}
				{/* <p>This classic massage works wonders for circulation in feet and it causes great stress relief. </p>
        <p>It also improves sleep, injury prevention and faster recovery time. Surprisingly, it also alleviates headaches and migraines, as well as decreases the feeling of anxiety. </p>
        <p>The perfect option for people that stand or walk around a lot in their day-to-day lives.</p>

        <h5>Maybe you’d be interested in a very theraputic head massage. </h5>
        <p>A very good pick for people with constant headaches. Incredibly refreshing.</p>
        <p> The massage helps to increase joint mobility and flexibility, improveblood circulation and lymphatic flow. </p>
        <p>It also frees knots built up by tension, relaxes tissues and aids with releasing of toxins from your body.</p>
        <img src="https://sabaitherapist.co.uk/wp-content/uploads/2019/02/fotolia_6819914_subscription_xxl-2048x1229-e1549637055110.jpg" width="100%" height="100%" alt="" title="fotolia_6819914_subscription_xxl-2048×1229" className="" srcSet="https://sabaitherapist.co.uk/wp-content/uploads/2019/02/fotolia_6819914_subscription_xxl-2048x1229-e1549637055110-200x120.jpg 200w, https://sabaitherapist.co.uk/wp-content/uploads/2019/02/fotolia_6819914_subscription_xxl-2048x1229-e1549637055110-400x240.jpg 400w, https://sabaitherapist.co.uk/wp-content/uploads/2019/02/fotolia_6819914_subscription_xxl-2048x1229-e1549637055110-600x360.jpg 600w, https://sabaitherapist.co.uk/wp-content/uploads/2019/02/fotolia_6819914_subscription_xxl-2048x1229-e1549637055110-800x480.jpg 800w, https://sabaitherapist.co.uk/wp-content/uploads/2019/02/fotolia_6819914_subscription_xxl-2048x1229-e1549637055110-1200x720.jpg 1200w, https://sabaitherapist.co.uk/wp-content/uploads/2019/02/fotolia_6819914_subscription_xxl-2048x1229-e1549637055110.jpg 2048w" sizes="(max-width: 800px) 100vw, 2048px"></img> */}

			</Container>
		</>
	);
};

export default Home;