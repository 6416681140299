import React, { useCallback, useMemo, useState } from 'react';
import { Container } from 'reactstrap';
import './aboutusreview.scss';
import { default as EmployeeList, Employee } from '../api/employees';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { GoogleReview } from '../api/reviews';

export type ReviewPropType = {
    review: GoogleReview
};

const AboutUsReview: React.FC<ReviewPropType> = (props) => {

    const customerReviewText = useMemo(() => {
        if (props.review.text.length > 300){
            return props.review.text.substr(0, 300) + "...";
        }
        return props.review.text;
    }, [props.review.text]);

    return (
        <div className="aboutus-review">
            <a href={props.review.author_url} className="aboutus-review__author-pic">
                <img src={props.review.profile_photo_url} alt={props.review.author_name}></img>
            </a>
            <div className="aboutus-review__info">
                <p className="aboutus-review__author">{props.review.author_name}</p>
                <div className="aboutus-review__rating">
                    gave&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faStar}
                        size="1x" className="aboutus-review__star" fontWeight="100" />
                    <FontAwesomeIcon icon={faStar}
                        size="1x" className="aboutus-review__star" fontWeight="100" />
                    <FontAwesomeIcon icon={faStar}
                        size="1x" className="aboutus-review__star" fontWeight="100" />
                    <FontAwesomeIcon icon={faStar}
                        size="1x" className="aboutus-review__star" fontWeight="100" />
                    <FontAwesomeIcon icon={faStar}
                        size="1x" className="aboutus-review__star" fontWeight="100" />
                    
                </div>
                <p className="aboutus-review__time">{props.review.relative_time_description}</p>

            </div>
            <p className="aboutus-review__text --mobileOnly">{customerReviewText}</p>
            <p className="aboutus-review__text --rest">{props.review.text}</p>
        </div>
    );
};

export default AboutUsReview;