import React, { useCallback, useEffect, useContext, useState } from 'react';
import MediaQueryContext from '../../components/mediaquery/context';
import { useStore } from '../../hooks/store';

type Props = {
    children: React.ReactNode;
};

const MediaQuery: React.FC<Props> = (params) => {
    const mqc = useContext(MediaQueryContext);
    const [matchMediaList, setMatchMedia] = useState([] as MediaQueryList[]);
    var {dispatch} = useStore(false, ['MEDIA_QUERY']);//todo: try to pass action type so that dispatch have explicity type check

    const widthCallBack = useCallback((e: MediaQueryListEvent) => {
        dispatch("UPDATE_BREAKPOINT_STATUS", e.media, e.matches, mqc);
    }, [dispatch, mqc]);

    useEffect(() => {
        let newMediaList: MediaQueryList[] = [];
        const keys = Object.keys(mqc.breakpoints);

        keys.forEach((breakpoint: string) => {
            const query = mqc.breakpoints[breakpoint];
            //console.log(query);
            const matchMedia : MediaQueryList = window.matchMedia(query);
            dispatch("SET_BREAKPOINT_QUERIES", query, breakpoint);
            dispatch("UPDATE_BREAKPOINT_STATUS", matchMedia.media, matchMedia.matches);
            matchMedia.addListener(widthCallBack);
            newMediaList.push(matchMedia);   
        });
        // mqc.widthMQ!.forEach((value, key) => {
        //     const matchMedia: MediaQueryList = window.matchMedia(key);
        //     dispatch("MEDIAQUERY_STATUS_CHANGE", matchMedia.media, matchMedia.matches, mqc);
        //     matchMedia.addListener(widthCallBack);
        //     newMediaList.push(matchMedia);
        // });
        setMatchMedia(newMediaList);
        return () => {
            matchMediaList.forEach((matchMedia) => {
                matchMedia.removeListener(widthCallBack);
            })
        };
    }, []);
    return (<>{params.children}</>);
};

export default MediaQuery;