import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Carousel, CarouselItem, Container } from 'reactstrap';
import { default as EmployeeList, Employee } from './api/employees';
import './aboutus/aboutus.scss';
import { GoogleReview, GoogleReviewResult } from './api/reviews';
import AboutUsReview from './reviews/AboutUsReview';
import { useHistory } from 'react-router-dom';

const AboutUs: React.FC = () => {
    const [reviewResponse, setReviewResponse] = useState<GoogleReviewResult>(null);
    const [headerImageLoaded, setHeaderImageLoaded] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const history = useHistory();

    const headerImageTextBlock = useMemo(() => {
        return (
            <div className={"aboutus__image" + (headerImageLoaded ? ' -background' : ' ')}>
                <div className="aboutus__image-titlecontainer">
                    <p className="aboutus__image-title1">We help you</p>
                    <p className="aboutus__image-title2">To relax your body</p>
                </div>
            </div>
        );
    }, [headerImageLoaded]);

    useEffect(() => {
        fetch('/api/googlereview/reviews', {
            method: 'GET'
        }).then(function (response) {
            return response.json();
        }).then((result) => {
            setReviewResponse(result.result);
            // setError(result.status !== "OK");
        }).catch((error) => {
            // setError(true);
        });
    }, []);

    useEffect(() => {
        let image = new Image(1, 1);
        image.src = "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/assets/home/aboutus3.jpg";
        if (image !== null && image !== undefined) {
            image.onload = function (event: any) {
                setHeaderImageLoaded(true);
            };
        }
    }, []);

    const handleBookNowClick = useCallback(() => {
        window.scrollTo(0,0);
        history.push('/booking')
    }, [history]);

    const handleEmoloyeeClick = useCallback(() => {
        window.scrollTo(0,0);
        history.push('/team')
    }, [history]);

    const myReviews = useMemo(() => {
        if (reviewResponse !== null) {
            return reviewResponse!.reviews.map((review: GoogleReview, index: number) =>
                <CarouselItem key={index} className="" >
                    <AboutUsReview key={index} review={review}></AboutUsReview>
                </CarouselItem>
            );

        }
    }, [reviewResponse]);

    // const myReviews2 = useMemo(() => {
    //     if (reviewResponse !== null) {
    //         return reviewResponse!.reviews.map((review: GoogleReview, index: number) =>


    //             <AboutUsReview key={index} review={review}></AboutUsReview>
    //         );

    //     }
    // }, [reviewResponse]);

    const massages = useMemo(() => {
        return EmployeeList.map((employee: Employee, index: number) => {
            if (employee.key !== 'any') {
                return (
                    <div key={index} className="aboutus-massages__massage">
                        <img src={employee.image} alt={employee.name}></img>
                        {/* <div className="aboutus-massages__massage-overlay"></div>
                         */}
                        <p className="aboutus-massages__massage-name">{employee.name}</p>
                        {/* <Button color="primary" className="aboutus-massages__massage-readmore">Read More</Button> */}
                    </div>
                );
            }
        });
    }, []);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === reviewResponse!.reviews.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? reviewResponse!.reviews.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    

    return (
        <>
            <Container className="page-container aboutus">

                {headerImageTextBlock}
                <div className="aboutus__section1">
                    <div className="aboutus__section1-container">
                        <p className="aboutus__title2">What we do?</p>
                    </div>
                </div>
                <div className="aboutus__section2">
                    <div className="aboutus__section2-container">
                        <p>We are spreading positive energy. Every person that had a massage in their life before knows quite
                        well that it’s more than just contact – it also energy,
                        which you can more or less experience and feel flowing trough your body.
                        Here at Sabai Sabai Thai Massage Therapy we only send you positive energy and take away the negative energy
                        accumulated troughout an everyday, stressful life. Balanced life energy causes everything to turn out
                        better and makes one attract correct people. We encourage you to try our massages,
                             where we will relax your body, and help you balance your energy.</p>
                    </div>
                </div>
                <div className="aboutus__section1">
                    <div className="aboutus__section1-container">
                        <p className="aboutus__title2">Why choose us?</p>
                    </div>
                </div>
                <div className="aboutus__section2">
                    <div className="aboutus__section2-container">
                        <p>Our massage salon was made with people in mind; people suffering from problems such as: stress-induced pain and tension,
                        headaches and migraines, back pain and joint stiffness, low blood circulation as well as anxiety and depression. It is also a great place for people who want to take care of their health, they want to achieve more in their lives,
                        learn more about their body, while giving it what it needs. Our salon is a very cose place, that gets cleaned after every client. We listen to our clients every day,
                        rising the standards and building the salon according to your wishes and preferences.</p>
                    </div>
                </div>
                <div className="aboutus__section1">
                    <div className="aboutus__section1-container">
                        <p className="aboutus__title2">Our team</p>
                    </div>
                </div>
                <div className="aboutus__section2 massage-section">
                    <div className="aboutus__section1-container aboutus-massages">
                        {massages}
                    </div>
                </div>    
                <div className="aboutus__section1">
                    <div className="aboutus__section1-container">
                        <p className="aboutus__title2">Gallery</p>
                    </div>
                </div>            
                <div className="aboutus__section2 massage-section">
                    <div className="aboutus__section1-container aboutus-massages">
                        <div className="aboutus-massages__group">
                            <img src={"https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/employee/group3.jpg"} alt="Weekends dress code" title={"Weekends dress code"}></img>
                        </div>
                        <div className="aboutus-massages__group">
                            <img src={"https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/employee/group5.jpg"} alt={"Weekdays dress code"} title={"Weekdays dress code"} className="-group2"></img>
                        </div>
                    </div>
                </div>
                {/* <div className="aboutus__section1">
                    <div className="aboutus__section1-container">
                        <p className="aboutus__title2">What people say?</p>
                    </div>
                </div> */}
                <div className="aboutus__section2 customer-reviews">
                    <p className="aboutus__title2">What people say?</p>
                    {reviewResponse !== null &&
                        (<Carousel
                            activeIndex={activeIndex}
                            next={next}
                            previous={previous}
                            className=""
                            autoPlay={true}>
                            {myReviews}
                        </Carousel>)
                    }
                    {/* {myReviews2} */}
                </div>
                <div className="aboutus__section1">
                    <div className="aboutus__section1-container">
                        <p className="aboutus__title2">Book your massage service at Sabai Sabai Thai Therapist</p>
                    </div>
                </div>

                <div className="aboutus__section2">
                    <div className="aboutus__section2-container">
                        <div className="aboutus-booknow__titlecontainer">
                            <p >Do not miss the wonderful massage experience at the Sabai Sabai Thai Therapist</p>
                            <p >45 Meadway Precinct, Reading, RG30 4AA, United Kingdom</p>
                            {/* <Button color="primary" className="aboutus-booknow__action" onClick={handleBookNowClick}>Book Now</Button> */}
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default AboutUs;