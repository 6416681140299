import React, { useMemo } from 'react';
// import useMediaQuery from '../../hooks/mediaquery';
// import MediaQueryContext from './context';
// import { mediaQueryStateType } from '../../store/mediaquery';
import { useStore } from '../../hooks/store';

type Props = {
    up?: string;
    down?: string;
    equal?: string[];
    query?: string;
    children: React.ReactNode;
    deviceWidth?: number; // Use it to override actual device width. Useful for testing/debugging
    //state: mediaQueryStateType;
};

const BreakPoint: React.FC<Props> = (props) => {
    // console.log("in media query redner");
    //const mqc = useContext(MediaQueryContext);
    // if (!mqc.breakpoints) {
    //     throw Error("No breakpoints applied in context");
    // }

    var {state} = useStore(true, ['MEDIA_QUERY']);


    const allowedBreakPoints = useMemo(() => {
        // console.log("in usememo");
        let allowedBreakPoints: string[] = [];
        if (props.equal) {
            props.equal.forEach((item) => {
                allowedBreakPoints.push(item);
            })
        }
        else if (props.up) {
            let start: boolean = false;
            state.MEDIA_QUERY.queries.forEach((value: string, key: string) => {
                 //console.log(value, props.up);
                if (value === props.up) {
                    start = true;
                }
                if (start)
                    allowedBreakPoints.push(value);
            });
        }
        else if (props.down) {
            let start: boolean = true;
            state.MEDIA_QUERY.queries.forEach((value: string, key: string) => {
                if (start)
                    allowedBreakPoints.push(value);
                if (value === props.down) {
                    start = false;
                }
            });
        }
        return allowedBreakPoints;
    }, [props.down, props.equal, props.up, state.MEDIA_QUERY.queries]);

    const visibility = useMemo(() => {
        return allowedBreakPoints.indexOf(state.MEDIA_QUERY.current) !== -1;
    }, [allowedBreakPoints, state.MEDIA_QUERY]);

    //console.log(allowedBreakPoints, visibility, state.MEDIA_QUERY.current)

    return (<>{visibility && props.children}</>);

};

export default BreakPoint;