import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Massage } from '../api/massages';


type MassageViewPropType = {
    // key: string,
    // name: string,
    // // description: string,
    // image: string,
    // duration: string,
    // price: string,
    // dispatch: any,
    massage: Massage,
    selected?: boolean
};

const MassageView: React.FC<MassageViewPropType> = (props) => {
    const imageIndex = useMemo(() => {
        return Math.floor(Math.random() * (props.massage.images.length - 1)) + 1;
    }, [props.massage.images.length]);

    return (
        <>
            {/* <div className="booking__massage"> */}
            <img src={props.massage.image + props.massage.images[imageIndex]} alt=""></img><div>{props.massage.name}</div>
            {props.selected! && (
                <>
                    <div className="--selected"></div>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className="check-icon" />
                </>
            )}

            {/* </div> */}
        </>
    );
};

export default MassageView;
