import { initStore } from "../hooks/store";
import { Massage } from "../components/api/massages";
import { Employee } from "../components/api/employees";
import { AvailabilitySlot } from "../components/api/availability";

//BookingReducer

export type Customer = {
    firstname: string,
    lastname: string,
    phonenumber: string,
    email: string
};

export type State = {
    // events: DBEvent[];
    inprogress: boolean;
    error?: string;
    step: number;
    massage: null | Massage;
    employee_key: null | string;
    employee_name: null | string;
    date: null | Date;
    slot: null | string;
    customer: null | Customer;
    bookingId: null | string;
    clientSecret: null | string;
    errors: JSX.Element[];
    nextProgress: boolean;
    bookingRef: string;
    slots: undefined | AvailabilitySlot;
};

export const initialState: State = {
    // events: [],
    error: "",
    inprogress: false,
    step: 1,
    massage: null,
    employee_key: 'any',
    employee_name: 'Any',
    date: null,
    slot: null,
    customer: {
        firstname: '',
        lastname: '',
        phonenumber: '',
        email: ''
    },
    bookingId: null,
    clientSecret: null,
    errors: [],
    nextProgress: false,
    bookingRef: '',
    slots: undefined
};

export type Action =
    | { type: 'SELECT_MASSAGE', massage: Massage }
    | { type: 'GOTO_STEP', step: number }
    | { type: 'SELECT_EMPLOYEE', employee: Employee }
    //| { type: 'SELECT_DATE', date: Date, slots: AvailabilitySlot }
    | { type: 'SELECT_SLOT', date: Date, slot: string }
    | { type: 'UPDATE_CUSTOMER_DETAILS', firstname: string, lastname: string, phonenumber: string, email: string }
    | { type: 'CLEAR' }
    | { type: 'UPDATE_CLIENT_SECRET', bookingId: string, clientSecret: string }
    | { type: 'UPDATE_BOOKING_REF', bookingRef: string }
    | { type: 'SET_ERROR', errors: JSX.Element[] }
    | { type: 'UPDATE_NEXT_PROGRESS', progress: boolean };


const reducer = (previousstate: State, action: Action) => {

    switch (action.type) {
        case "SELECT_MASSAGE":
            return {
                ...previousstate, massage: action.massage, employee_key: "any",
                employee_name: "Any", date: null, slot: null, slots: undefined
            };
        case "SELECT_EMPLOYEE":
            return {
                ...previousstate, employee_key: action.employee.key,
                employee_name: action.employee.name
            };
        // case "SELECT_DATE":
        //     return { ...previousstate, date: action.date, slot: null, slots: action.slots };
        case "SELECT_SLOT":
            return { ...previousstate, date: action.date, slot: action.slot };
        case "GOTO_STEP":
            return { ...previousstate, step: action.step };
        case "UPDATE_CUSTOMER_DETAILS":
            return {
                ...previousstate, customer: {
                    firstname: action.firstname,
                    lastname: action.lastname,
                    phonenumber: action.phonenumber,
                    email: action.email
                }
            };
        case "CLEAR":
            return initialState;
        case "UPDATE_CLIENT_SECRET":
            return { ...previousstate, clientSecret: action.clientSecret, bookingId: action.bookingId };
        case "UPDATE_BOOKING_REF":
            return { ...previousstate, bookingRef: action.bookingRef };
        case "SET_ERROR":
            return { ...previousstate, errors: action.errors };
        case "UPDATE_NEXT_PROGRESS":
            return { ...previousstate, nextProgress: action.progress };
        default:
            return previousstate;
    }
};


export const actions = {
    SELECT_MASSAGE: (previousState: State, massage: Massage) => {
        return reducer(previousState, { type: 'SELECT_MASSAGE', massage: massage });
    },
    SELECT_EMPLOYEE: (previousState: State, employee: Employee) => {
        return reducer(previousState, { type: 'SELECT_EMPLOYEE', employee: employee });
    },
    // SELECT_DATE: (previousState: State, date: Date, slots: AvailabilitySlot) => {
    //     return reducer(previousState, { type: 'SELECT_DATE', date: date, slots: slots });
    // },
    SELECT_SLOT: (previousState: State, date: Date, slot: string) => {
        return reducer(previousState, { type: 'SELECT_SLOT', date: date, slot: slot });
    },
    GOTO_STEP: (previousState: State, step: number) => {
        return reducer(previousState, { type: 'GOTO_STEP', step: step });
    },
    UPDATE_CUSTOMER_DETAILS: (previousState: State, firstname: string, lastname: string, phonenumber: string, email: string) => {
        return reducer(previousState, { type: 'UPDATE_CUSTOMER_DETAILS', firstname, lastname, phonenumber, email });
    },
    CLEAR: (previousState: State, step: number) => {
        return reducer(previousState, { type: 'CLEAR' });
    },
    UPDATE_CLIENT_SECRET: (previousState: State, bookingId: string, clientSecret: string) => {
        return reducer(previousState, { type: 'UPDATE_CLIENT_SECRET', bookingId, clientSecret });
    },
    UPDATE_BOOKING_REF: (previousState: State, bookingRef: string) => {
        return reducer(previousState, { type: 'UPDATE_BOOKING_REF', bookingRef });
    },
    SET_ERROR: (previousState: State, errors: JSX.Element[]) => {
        return reducer(previousState, { type: 'SET_ERROR', errors });
    },
    UPDATE_NEXT_PROGRESS: (previousState: State, progress: boolean) => {
        return reducer(previousState, { type: 'UPDATE_NEXT_PROGRESS', progress });
    }
};

const configureStore = () => {
    initStore("BOOKING", true, actions, initialState);
};

export default configureStore;