import React, { } from 'react';
// import configureMediaQueryStore from '../../store/mediaquery';

export type widthMediaQueryType = Map<string, string>;

export type mediaQueryContextType = {
    breakpoints: any;
    // defaultMode: string;
};

const defaultValue: mediaQueryContextType = {
    // defaultMode: "XS",
    breakpoints: {
        XS: '(min-width: 0px)',
        SM: '(min-width: 576px)',
        MD: '(min-width: 768px)',
        LG: '(min-width: 992px)',
        XL: '(min-width: 1200px)'
    }
};

const initialValue: mediaQueryContextType = { ...defaultValue };

export const MediaQueryContext = React.createContext(defaultValue);

export const MediaQueryContextProvider: React.FC = (props: any) => {
    // configureMediaQueryStore();
    // let widhtMediaQuery: widthMediaQuery = new Map();
    // widhtMediaQuery.set('SM', '(min-width: 576px)');
    // widhtMediaQuery.set('MD', '(min-width: 768px)');
    // widhtMediaQuery.set('LG', '(min-width: 992px)');
    // widhtMediaQuery.set('XL', '(min-width: 1200px)');
    // let initvalue = { widthMQ: widhtMediaQuery, defaultMode: "XS" };

    return (
        <MediaQueryContext.Provider value={initialValue}>
            {props.children}
        </MediaQueryContext.Provider>
    );
};

export default MediaQueryContext;