import React, { } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Employee } from '../api/employees';

type EmployeeViewPropType = {
    employee: Employee,
    selected?: boolean
};

const EmployeeView: React.FC<EmployeeViewPropType> = (props) => {

    return (
        <>
            <img src={props.employee.image} alt=""></img><div>{props.employee.name}</div>
            {props.selected! && (
                <>
                    <div className="--selected"></div>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className="check-icon" />
                </>
            )}

        </>
    );
};

export default EmployeeView;
