import React, { Component } from 'react';
import { Container } from 'reactstrap';
// import { NavMenu } from './NavMenu';
import TopNavigation from './header/TopNavigation';
import HeaderMenu from './header/HeaderMenu';
import MediaQuery from '../components/mediaquery/mediaquery';
import BreakPoint from '../components/mediaquery/breakpoint';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <>
        <MediaQuery>
          {/* <NavMenu /> */}
          {/* <NavMenu /> */}
          {/* <BreakPoint down="MD" > */}
          <TopNavigation></TopNavigation>
          {/* </BreakPoint> */}
          {/* <BreakPoint equal={["MD"]} >
            <HeaderMenu></HeaderMenu>
          </BreakPoint> */}
          {/* <Container className="page-container"> */}
            {this.props.children}
          {/* </Container> */}
        </MediaQuery>
      </>
    );
  }
}
